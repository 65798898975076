import React from "react";
import { Link } from "react-router-dom";

import { connect } from "react-redux";

import "../styles/header.scss";

import {
  handleClickOnHome,
  resetForm,
  setModalConfirm,
  setPageToGo,
} from "../store/actions";

import logo from "../assets/logoLoukakode.png";

const Header = ({ handleClickOnHome, isBlocking }) => {
  const month = [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Aout",
    "Septembre",
    "Octobre",
    "Novembre",
    "Decembre",
  ];

  const day = [
    "Dimanche",
    "Lundi",
    "Mardi",
    "Mercredi",
    "Jeudi",
    "Vendredi",
    "Samedi",
  ];

  const today = new Date();
  return (
    <header className='Header'>
      <Link to='/' onClick={(e) => handleClickOnHome(e, isBlocking)}>
        <h1 className='Header__title'>
          <img
            src={logo}
            className='Header__title__logo'
            alt='logo_loukakode'
          />
          LOUKAKODE
        </h1>
        <p className='Header__subtitle'>::DéveloppeurWebFullstack</p>
        <p className='Header__town'>Paris</p>
        <p className='Header__date'>
          {day[today.getDay()]} {today.getDate()} {month[today.getMonth()]}{" "}
          {today.getFullYear()}
        </p>
      </Link>
    </header>
  );
};

const mapStateToProps = (state) => ({
  isBlocking: state.contactForm.isBlocking,
});

const mapDispatchToProps = (dispatch) => ({
  handleClickOnHome: (e, isBlocking) => {
    if (isBlocking) {
      e.preventDefault();
      dispatch(setModalConfirm());
      dispatch(setPageToGo("/"));
    } else {
      dispatch(handleClickOnHome());
      dispatch(resetForm());
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
