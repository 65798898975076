import Fade from "react-reveal/Fade";

import { NavLink } from "react-router-dom";

import { connect } from "react-redux";

import "../styles/notfound.scss";


const NotFound = () => {
  return (
    <Fade>
      <div className='NotFound'>
      <h1 className='NotFound__error'>404</h1>
      <p className='NotFound__message'>Cette ressource est introuvable...</p>
      <NavLink to='/' className='NotFound__button'>
          Retour à l'accueil
        </NavLink>
    </div>
    </Fade>
    
  );
};

const mapDispatchToProps = (dispatch) => ({
  
});

export default connect(null, mapDispatchToProps)(NotFound);
