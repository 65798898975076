export const SET_IS_HOME = "SET_IS_HOME";
export const SET_HOVER_ON_CAREER = "SET_HOVER_ON_CAREER";
export const SET_HOVER_ON_SKILLS = "SET_HOVER_ON_SKILLS";
export const SET_HOVER_ON_PROJECTS = "SET_HOVER_ON_PROJECTS";
export const SET_HOVER_ON_CONTACT = "SET_HOVER_ON_CONTACT";
export const CHANGE_INPUT_VALUE = "CHANGE_INPUT_VALUE";
export const DISPLAY_ERRORS = "DISPLAY_ERRORS";
export const SET_SUCCESS_MESSAGE = "SET_SUCCESS_MESSAGE";
export const SET_ERROR_MESSAGE = "SET_ERROR_MESSAGE";
export const CLOSE_FLASH_MESSAGE = "CLOSE_FLASH_MESSAGE";
export const RESET_FORM = "RESET_FORM";
export const SET_IS_LOADING_TOOGLE= "SET_IS_LOADING_TOOGLE";
export const SET_IS_BLOCKING = "SET_IS_BLOCKING";
export const SET_MODAL_CONFIRM = "SET_MODAL_CONFIRM";
export const CLOSE_MODAL_CONFIRM = "CLOSE_MODAL_CONFIRM";
export const SET_PAGE_TO_GO = "SET_PAGE_TO_GO";
export const LEAVE_PAGE = "LEAVE_PAGE";

export const handleClickOnHome = () => ({
  type: SET_IS_HOME,
});

export const setHoverOnCareer = () => ({
  type: SET_HOVER_ON_CAREER,
});

export const setHoverOnSkills = () => ({
  type: SET_HOVER_ON_SKILLS,
});

export const setHoverOnProjects = () => ({
  type: SET_HOVER_ON_PROJECTS,
});

export const setHoverOnContact = () => ({
  type: SET_HOVER_ON_CONTACT,
});

export const changeInputValue = (newValue, field, blockedField) => ({
  type: CHANGE_INPUT_VALUE,
  newValue,
  field,
  blockedField,
});

export const displayErrors = (errors) => ({
  type: DISPLAY_ERRORS,
  errors,
});

export const displaySuccessMessage = () => ({
  type: SET_SUCCESS_MESSAGE,
});

export const setErrorMessage = () => ({
  type: SET_ERROR_MESSAGE,
});

export const closeFlashMessage = () => ({
  type: CLOSE_FLASH_MESSAGE,
});

export const resetForm = () => ({
  type: RESET_FORM,
});

export const setIsLoadingToogle = () => ({
  type: SET_IS_LOADING_TOOGLE,
})

export const setIsBlocking = (isBlocking) => ({
  type: SET_IS_BLOCKING,
  isBlocking
})

export const setModalConfirm = () => ({
  type: SET_MODAL_CONFIRM,
})

export const closeModalConfirm = () => ({
  type: CLOSE_MODAL_CONFIRM,
})

export const setPageToGo = (pageToGo) => ({
  type: SET_PAGE_TO_GO,
  pageToGo
})

export const leavePage = () => ({
  type: LEAVE_PAGE,
})

