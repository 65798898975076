import React from "react";
import { NavLink } from "react-router-dom";

import { connect } from "react-redux";

import "../styles/footer.scss";

import {
  setHoverOnCareer,
  setHoverOnSkills,
  setHoverOnProjects,
  setHoverOnContact,
  resetForm,
  setModalConfirm,
  setPageToGo
} from "../store/actions";

const scrollToTop = () => {
  window.scroll({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
};

const Footer = ({
  handleClickOnCareer,
  handleClickOnSkills,
  handleClickOnProjects,
  handleClickOnContact,
  isBlocking,
}) => {
  const today = new Date();

  return (
    <footer className='Footer'>
      <div className='Footer__SecondaryNav'>
        <NavLink
          to='/parcours'
          className='Footer__SecondaryNav__item'
          onClick={(e) => handleClickOnCareer(e, isBlocking, '/parcours')}>
          Parcours
        </NavLink>
        <NavLink
          to='/skills'
          className='Footer__SecondaryNav__item'
          onClick={(e) => handleClickOnSkills(e, isBlocking, '/skills')}>
          Skills
        </NavLink>
        <NavLink
          to='/projets'
          className='Footer__SecondaryNav__item'
          onClick={(e) => handleClickOnProjects(e, isBlocking, '/projets')}>
          Projets
        </NavLink>
        <NavLink
          to='/contact'
          className='Footer__SecondaryNav__item'
          onClick={(e) => handleClickOnContact(e, isBlocking, '/contact')}>
          Contact
        </NavLink>
      </div>
      <div className='Footer__SocialMedias'>
        <a
          href='https://www.linkedin.com/in/pierre-yves-loukakou-a33473209/'
          target='_blank'
          rel='noopener noreferrer'
          className='Footer__SocialMedias__item'>
          <svg
            id='Capa_1'
            enableBackground='new 0 0 512 512'
            height='30'
            viewBox='0 0 512 512'
            width='30'
            xmlns='http://www.w3.org/2000/svg'>
            <g>
              <path
                className='logo'
                d='m7.813 509.935h132.571v-348.613h-132.571zm30-318.613h72.571v288.612h-72.571z'
              />
              <path
                className='logo'
                d='m74.099 2.065c-40.859 0-74.099 33.241-74.099 74.099s33.24 74.1 74.099 74.1 74.1-33.241 74.1-74.1-33.242-74.099-74.1-74.099zm-44.099 74.099c0-24.316 19.782-44.099 44.099-44.099s44.1 19.782 44.1 44.099-19.783 44.1-44.1 44.1-44.099-19.784-44.099-44.1z'
              />
              <path
                className='logo'
                d='m511.679 270.79c-4.718-67.855-61.318-120.144-131.489-120.144-31.387 0-61.016 10.912-84.361 30.274v-19.6h-127.03v348.613h132.572v-190.664c0-21.488 17.481-38.97 38.97-38.97 21.487 0 38.969 17.482 38.969 38.979l.128 190.656h132.562v-238.822zm-240.308 209.145h-72.572v-288.614h67.029v42.847l24.005.138 4.46-6.924c18.85-29.265 50.961-46.735 85.897-46.735 55.836 0 100.543 42.602 101.78 96.985l.03 202.303h-72.582l-.108-160.666c0-38.03-30.94-68.97-68.969-68.97-38.03 0-68.97 30.94-68.97 68.97z'
              />
            </g>
          </svg>
        </a>
        <a
          href='https://github.com/pikloo'
          target='_blank'
          rel='noopener noreferrer'
          className='Footer__SocialMedias__item'>
          <svg
            enableBackground='new 0 0 24 24'
            height='30'
            viewBox='0 0 24 24'
            width='30'
            xmlns='http://www.w3.org/2000/svg'>
            <path
              className='logo'
              d='m.184 10.462c-.779 4.906 1.401 10.823 8.123 13.006.12.022.231.032.335.032.782 0 1.32-.582 1.32-1.3-.097-.523.383-2.642-.92-2.357-2.519.536-2.821-.871-3.205-1.607 1.086 1.394 2.718 1.359 3.949.819.683-.3.326-1.064.65-1.343.496-.426.244-1.243-.407-1.314-2.314-.255-4.457-1.001-4.457-4.702 0-2.168 1.505-2.362 1.09-3.269-.015-.033-.333-.754-.045-1.849 1.419.262 2.072 1.28 2.753 1.097 1.687-.46 3.544-.46 5.23 0 .704.189 1.207-.801 2.738-1.103.441 1.654-.473 2.058.103 2.677.632.68.953 1.503.953 2.447 0 5.564-4.717 3.957-5.101 5.22-.088.288.005.599.235.792.61.513.53 1.83.465 2.889-.067 1.098-.125 2.045.482 2.579.214.19.595.393 1.284.253 6.634-2.131 8.83-8.022 8.063-12.917-2.096-13.368-21.526-13.352-23.638-.05zm8.27 10.978.004.505c-.523-.181-1.015-.39-1.475-.623.425.109.913.156 1.471.118zm.37-3.7c-.005.026-.01.053-.015.08-.853.252-1.509.001-1.957-.752 0-.001 0-.001-.001-.002.68.364 1.381.56 1.973.674zm3.176-15.74c11.833 0 14.502 16.267 3.469 19.941-.038-.297-.003-.857.021-1.252.058-.951.126-2.059-.213-2.985 5.088-1.059 5.513-6.646 3.554-9.135.243-.952.145-3.189-.729-3.463-.206-.065-1.305-.304-3.437 1.037-1.741-.416-3.62-.417-5.361 0-1.064-.667-3.462-1.752-3.922-.6-.534 1.342-.407 2.427-.248 3.03-1.739 2.204-1.218 5.894.534 7.626-.993-.475-2.361-.637-2.656.314-.323 1.037.912.911 1.679 2.804.073.236.208.513.415.788-6.811-5.565-3.525-18.105 6.894-18.105z'
            />
          </svg>
        </a>
        <a
          href='https://twitter.com/pikdev'
          target='_blank'
          rel='noopener noreferrer'
          className='Footer__SocialMedias__item'>
          <svg
            height='30'
            viewBox='0 0 368 368'
            width='30'
            xmlns='http://www.w3.org/2000/svg'>
            <path
              className='logo'
              d='m328 0h-288c-22.054688 0-40 17.945312-40 40v288c0 22.054688 17.945312 40 40 40h288c22.054688 0 40-17.945312 40-40v-288c0-22.054688-17.945312-40-40-40zm24 328c0 13.230469-10.769531 24-24 24h-288c-13.230469 0-24-10.769531-24-24v-288c0-13.230469 10.769531-24 24-24h288c13.230469 0 24 10.769531 24 24zm0 0'
            />
            <path
              className='logo'
              d='m248 224h-88v-32h56c22.054688 0 40-17.945312 40-40s-17.945312-40-40-40h-56v-8c0-22.054688-17.945312-40-40-40s-40 17.945312-40 40v128c0 39.703125 32.296875 72 72 72h96c22.054688 0 40-17.945312 40-40s-17.945312-40-40-40zm0 64h-96c-30.871094 0-56-25.128906-56-56v-128c0-13.230469 10.769531-24 24-24s24 10.769531 24 24v16c0 4.425781 3.574219 8 8 8h64c13.230469 0 24 10.769531 24 24s-10.769531 24-24 24h-64c-4.425781 0-8 3.574219-8 8v48c0 4.425781 3.574219 8 8 8h96c13.230469 0 24 10.769531 24 24s-10.769531 24-24 24zm0 0'
            />
          </svg>
        </a>
      </div>
      <div className='Footer__Copyright'>
        <p>{today.getFullYear()} &copy; loukakode</p>
      </div>
    </footer>
  );
};

const mapStateToProps = (state) => ({
  isBlocking: state.contactForm.isBlocking,
});

const mapDispatchToProps = (dispatch) => ({
  handleClickOnCareer: (e, isBlocking, pageToGo) => {
    if (isBlocking) {
      e.preventDefault();
      dispatch(setModalConfirm());
      dispatch(setPageToGo(pageToGo));
    } else {
      dispatch(setHoverOnCareer());
      dispatch(resetForm());
      scrollToTop();
    }
  },
  handleClickOnSkills: (e, isBlocking, pageToGo) => {
    if (isBlocking) {
      e.preventDefault();
      dispatch(setModalConfirm());
      dispatch(setPageToGo(pageToGo));
    } else {
      dispatch(setHoverOnSkills());
      dispatch(resetForm());
      scrollToTop();
    }
  },
  handleClickOnProjects: (e, isBlocking, pageToGo) => {
    if (isBlocking) {
      e.preventDefault();
      dispatch(setModalConfirm());
      dispatch(setPageToGo(pageToGo));
    } else {
      dispatch(setHoverOnProjects());
      dispatch(resetForm());
      scrollToTop();
    }
  },
  handleClickOnContact: (e, isBlocking, pageToGo) => {
    if (isBlocking) {
      e.preventDefault();
      dispatch(setModalConfirm());
      dispatch(setPageToGo(pageToGo));
    } else {
      dispatch(setHoverOnContact());
      scrollToTop();
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
