import Fade from "react-reveal/Fade";
import { Helmet } from "react-helmet";

import projects from "../datas/projects";

import "../styles/projects.scss";

const Projects = () => {
  return (
    <div className='Projects'>
      <Helmet>
        <title>LOUKAKODE -- Développeur Web Fullstack -- Projets</title>
        <meta name='description' content='Projets' />
        <meta
          name='keywords'
          content='react, redux ,php, node, express, symfony, doctrine'
        />
      </Helmet>
      {projects.map((project) => (
        <Fade key={project.title}>
          <section className='Projects__item'>
            <div className='Projects__item__container'>
              <h2 className='Projects__item__title'>{project.title}</h2>
              <p className='Projects__item__year'>{project.year}</p>
              <div className='Projects__item__preview'>
                {project.desktopPreviewPath && (
                  <img
                    src={project.desktopPreviewPath}
                    className='Projects__item__preview__desktop'
                    alt={project.title}
                  />
                )}
                {project.mobilePreviewPath && (
                  <img
                    src={project.mobilePreviewPath}
                    className='Projects__item__preview__mobile'
                    alt={project.title}
                  />
                )}
              </div>
              <p className='Projects__item__description'>
                {project.description}
              </p>
              <ul className='Projects__item__stackList'>
                <p className='Projects__item__stack'>Technologies: </p>
                {project.stack.map((item) => (
                  <li key={item} className='Projects__item__stack__techno'>{item}</li>
                ))}
              </ul>
              {project.url && (
                  <a
                    href={project.url}
                    target='_blank'
                    rel='noopener noreferrer'
                    className='Projects__item__button'>
                    Voir le site
                  </a>
                )}
            </div>
          </section>
        </Fade>
      ))}
    </div>
  );
};

export default Projects;
