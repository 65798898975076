const career = {
  jobs: [
    {
      title: "Chargé de projets base de données",
      company: "Rue du Commerce",
      place: "Saint-Ouen",
      startDate: "2013-03-18",
      endDate: "2021-09-01",
      missions: [
        "Gestion de problématiques techniques liées aux catalogues marchands ( Solution MarketPlace MIRAKL, HYBRIS Product Content Management, MYSQL)",
        "Gestion de projets d’améliorations d’outils interne et suivi d’incidents (JIRA)",
        "Mise en place d'un nouveau référentiel et d'une nouvelle arborescence des catégories de produits",
      ],
    },
    {
      title: "Développeur PHP",
      company: "JohnPaul, Service de Conciergerie de luxe",
      place: "Paris",
      startDate: "2012-04-01",
      endDate: "2012-12-31",
      missions: [
        "Développement de sites avec le Framework Zend 2 (PHP, Ajax)",
        "Intégration HTML, CSS, Javascript",
        "Développement d’API REST utilisant un e-CRM",
      ],
    },
    {
      title: "Webdesigner junior",
      company: "B.R.I.E.F",
      place: "Paris",
      startDate: "2011-04-01",
      endDate: "2012-03-31",
      missions: [
        "Réalisation de sites (Wordpress, Joomla, Prestashop)",
        "Réalisation de cahiers des charges et réponses à des appels d’offres",
        "Intégration HTML/CSS",
      ],
    },
    {
      title: "Développeur d'application web (alternance)",
      company: "Global Electronic Business",
      place: "Paris",
      startDate: "2006-09-01",
      endDate: "2008-12-31",
      missions: [
        "Développement d’application et mises à jour de sites",
        "Intégration HTML/CSS et mailing",
      ],
    },
  ],
  studies: [
    {
      title: "Formation Développeur Web/Fullstack",
      school: "Oclock",
      startDate: "2020-09-01",
      endDate: "2021-07-01",
      schoolLogoPath: "/images/Oclock.svg",
      schoolUrl: "https://oclock.io",
    },
    {
      title: "Licence professionelle Edition et Communication Numérique",
      school: "IESA Paris",
      startDate: "2006-09-01",
      endDate: "2008-12-31",
      schoolLogoPath: "/images/IESA.png",
      schoolUrl: "https://iesa.fr",
    },
    {
      title: "DUT Services et Réseaux de Communication",
      school: "IUT de Troyes",
      startDate: "2004-09-01",
      endDate: "2006-06-30",
      schoolLogoPath: "/images/IUT.png",
      schoolUrl: "https://www.iut-troyes.univ-reims.fr/",
    },
  ],
  degrees: [
    {
      title: "Certification Opquast Maitrise de la qualité Web",
      year: 2021,
      organization: "Opquast",
      labelLogoPath: "/images/opquast.svg",
      badgePath: "/images/badge_avance.svg",
      labelUrl: "https://www.opquast.com/"
    },
    {
      title: "Titre RNPC niveau V - Développeur web et web mobile",
      year: 2021
    },
    {
      title: "DUT Services et Réseaux de Communication",
      year: 2006
    }
  ]
};
export default career;
