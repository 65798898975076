import {
  SET_IS_HOME,
  SET_HOVER_ON_CAREER,
  SET_HOVER_ON_SKILLS,
  SET_HOVER_ON_PROJECTS,
  SET_HOVER_ON_CONTACT,
  CHANGE_INPUT_VALUE,
  DISPLAY_ERRORS,
  CLOSE_FLASH_MESSAGE,
  SET_SUCCESS_MESSAGE,
  RESET_FORM,
  SET_IS_LOADING_TOOGLE,
  SET_IS_BLOCKING,
  SET_MODAL_CONFIRM,
  CLOSE_MODAL_CONFIRM,
  SET_PAGE_TO_GO,
  LEAVE_PAGE,
} from "./actions";

const initialState = {
  isHome: false,
  career: false,
  skills: false,
  projects: false,
  contact: false,
  contactForm: {
    email: "",
    subject: "",
    message: "",
    errorsList: [],
    flashMessage: false,
    successMessage: "",
    isLoading: false,
    isBlocking: false,
    isModalConfirm: false,
    pageToGo: "/",
    leavePage: false,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_HOME:
      return {
        ...state,
        isHome: true,
        career: false,
        skills: false,
        projects: false,
        contact: false,
      };
    case SET_HOVER_ON_CAREER:
      return {
        ...state,
        isHome: false,
        career: true,
        skills: false,
        projects: false,
        contact: false,
      };
    case SET_HOVER_ON_SKILLS:
      return {
        ...state,
        isHome: false,
        career: false,
        skills: true,
        projects: false,
        contact: false,
      };
    case SET_HOVER_ON_PROJECTS:
      return {
        ...state,
        isHome: false,
        career: false,
        skills: false,
        projects: true,
        contact: false,
      };
    case SET_HOVER_ON_CONTACT:
      return {
        ...state,
        isHome: false,
        career: false,
        skills: false,
        projects: false,
        contact: true,
      };
    case CHANGE_INPUT_VALUE:
      return {
        ...state,
        contactForm: {
          ...state.contactForm,
          [action.field]: action.newValue,
        },
      };
    case DISPLAY_ERRORS:
      return {
        ...state,
        contactForm: {
          ...state.contactForm,
          errorsList: action.errors,
          flashMessage: true,
          successMessage: "",
        },
      };
    case CLOSE_FLASH_MESSAGE:
      return {
        ...state,
        contactForm: {
          ...state.contactForm,
          flashMessage: false,
        },
      };
    case SET_SUCCESS_MESSAGE:
      return {
        ...state,
        contactForm: {
          ...state.contactForm,
          errorsList: [],
          flashMessage: true,
          isLoading: false,
          email: "",
          subject: "",
          message: "",
          successMessage:
            "Votre message a été envoyé, une réponse vous sera envoyé dans les plus brefs délais",
        },
      };
    case RESET_FORM:
      return {
        ...state,
        contactForm: {
          ...state.contactForm,
          email: "",
          subject: "",
          message: "",
          errorsList: [],
          flashMessage: false,
          successMessage: "",
          leavePage: false
        },
      };
    case SET_IS_LOADING_TOOGLE:
      return {
        ...state,
        contactForm: {
          ...state.contactForm,
          isLoading: true,
          errorsList: [],
          successMessage: "",
        },
      };
    case SET_IS_BLOCKING:
      return {
        ...state,
        contactForm: {
          ...state.contactForm,
          isBlocking: action.isBlocking,
        },
      };
    case SET_MODAL_CONFIRM:
      return {
        ...state,
        contactForm: {
          ...state.contactForm,
          isModalConfirm: true,
        },
      };
    case CLOSE_MODAL_CONFIRM:
      return {
        ...state,
        contactForm: {
          ...state.contactForm,
          isModalConfirm: false,
        },
      };
    case SET_PAGE_TO_GO:
      return {
        ...state,
        contactForm: {
          ...state.contactForm,
          pageToGo: action.pageToGo,
        },
      };
    case LEAVE_PAGE:
      return {
        ...state,
        contactForm: {
          ...state.contactForm,
          leavePage: true,
        },
      };
    default:
      return state;
  }
};

export default reducer;
