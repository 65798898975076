import { NavLink } from "react-router-dom";
import Fade from "react-reveal/Fade";
import { Helmet } from "react-helmet";

import { connect } from "react-redux";

import "../styles/home.scss";

import {
  setHoverOnCareer,
  setHoverOnSkills,
  resetForm,
} from "../store/actions";

import loukakode from "../assets/loukakode2.png";
import loukakodeGif from "../assets/GIF-FINAL-LOUKAKODE-3.gif";

const scrollToTop = () => {
  window.scroll({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
}

const Home = ({
  handleClickOnCareer,
  handleClickOnSkills,
}) => {
  return (
    <Fade>
      <div className='Home'>
      <Helmet>
        <title>LOUKAKODE -- Développeur Web Fullstack</title>
        <meta name='description' content='Accueil' />
        <meta
          name='keywords'
          content='développeur, fullstack, paris, php, javascript, symfony, react'
        />
      </Helmet>
        <img className='Home__image' src={loukakode} alt="loukakode"/>
        <p className='Home__presentation'>
          Je suis <em>Pierre-Yves LOUKAKOU</em>, un développeur <em>curieux</em>{" "}
          et <em>passionné</em>, j'ai découvert le dévéloppement web pendant mon
          cursus universitaire dès 2004. Pendant 8 ans, je me suis ré-orienté
          vers la gestion de projet dans le e-commerce. Depuis 2020 j'ai décidé
          de revenir au développement en me spécialisant en
          <em> Symfony</em> et <em> React</em> notamment.
        </p>
      
      <div className='Home__action'>
        <NavLink to='/parcours' className='Home__action__button' onClick={handleClickOnCareer}>
          Parcours
        </NavLink>
        <NavLink to='/skills' className='Home__action__button' onClick={handleClickOnSkills}>
          Skills
        </NavLink>
      </div>
    </div>
    </Fade>
    
  );
};

const mapDispatchToProps = (dispatch) => ({
  handleClickOnCareer: () => {
    scrollToTop();
    dispatch(setHoverOnCareer());
    dispatch(resetForm());
  },
  handleClickOnSkills: () => {
    scrollToTop();
    dispatch(setHoverOnSkills());
    dispatch(resetForm());
  },
});

export default connect(null, mapDispatchToProps)(Home);
