import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import { useHistory } from "react-router-dom";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";

import Fade from "react-reveal/Fade";

import { connect } from "react-redux";

import CircularProgress from "@material-ui/core/CircularProgress";

import "../styles/contact.scss";

import {
  changeInputValue,
  displayErrors,
  closeFlashMessage,
  displaySuccessMessage,
  resetForm,
  setIsLoadingToogle,
  setIsBlocking,
  closeModalConfirm,
  leavePage,
  setHoverOnCareer,
  setHoverOnSkills,
  setHoverOnProjects,
  setHoverOnContact,
  setPageToGo,
} from "../store/actions";

import emailjs from "emailjs-com";

const SERVICE_ID = "service_o7q6zgg";
const TEMPLATE_ID = "template_7tkb52j";
const USER_ID = "user_YMDFgHrnYbSM7d3DhcWlG";

const scrollToTop = () => {
  window.scroll({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
};

const Contact = ({
  email,
  subject,
  message,
  handleChangeInput,
  displayErrors,
  errorsList,
  flashMessage,
  displaySuccessMessage,
  successMessage,
  resetForm,
  setIsLoadingToogle,
  setIsBlocking,
  isLoading,
  isBlocking,
  isModalConfirm,
  handleCloseModal,
  handleLeavePage,
  pageToGo,
  leavePage,
  setHoverOnCareer,
  setHoverOnSkills,
  setHoverOnProjects,
  setHoverOnContact,
  setPageToGo,
  contact,
}) => {
  let history = useHistory();

  useEffect(() => {
    if (email !== "" || subject !== "" || message !== "") {
      setIsBlocking(true);
    } else {
      setIsBlocking(false);
    }
  }, [email, subject, message, setIsBlocking]);

  //TODO ouvrir la modal lorsqu'on ferme la page ou au rafraichissement
  useEffect(()=> {
    if (isBlocking) {
      window.onbeforeunload = () => true
    } else {
      window.onbeforeunload = undefined
    }
  },[isBlocking])

  useEffect(() => {
    if (
      errorsList.length > 0 &&
      (email !== "" || subject !== "" || message !== "")
    ) {
      setIsBlocking(true);
    }
  }, [errorsList, setIsBlocking, email, subject, message]);

  useEffect(() => {
    if (leavePage) {
      history.push(pageToGo);
      setIsBlocking(false);
      resetForm();
      handleCloseModal();
      setPageToGo();
    }
    if (leavePage && pageToGo === "/parcours") {
      setHoverOnCareer();
    }
    if (leavePage && pageToGo === "/skills") {
      setHoverOnSkills();
    }
    if (leavePage && pageToGo === "/projets") {
      setHoverOnProjects();
    }
    if (leavePage && pageToGo === "/contact") {
      setHoverOnContact();
    }
  }, [
    leavePage,
    pageToGo,
    setIsBlocking,
    resetForm,
    handleCloseModal,
    setHoverOnCareer,
    setHoverOnSkills,
    setHoverOnContact,
    setPageToGo,
    history,
    setHoverOnProjects
  ]);

  const sendEmail = (e) => {
    e.preventDefault();
    setIsBlocking(false);
    scrollToTop();
    const errors = [];

    if (email !== "" && !email.match(/\b[\w.-]+@[\w.-]+\.\w{2,4}\b/)) {
      errors.push(`Cet email est invalide`);
    }

    if (email === "") {
      errors.push(`Votre adresse email est requise`);
    }

    if (subject === "") {
      errors.push(`Le sujet de votre message est requis`);
    }

    if (message === "") {
      errors.push(`Votre message est vide`);
    }
    if (errors.length > 0) {
      displayErrors(errors);
    } else {
      const formDatas = {
        email: email,
        subject: subject.trim(),
        message: message.trim(),
      };
      setIsLoadingToogle();
      emailjs.send(SERVICE_ID, TEMPLATE_ID, formDatas, USER_ID).then(
        () => {
          displaySuccessMessage();
        },
        (error) => {
          console.log(error);
        }
      );
    }
  };
  return (
    <Fade>
      <Helmet>
        <title>LOUKAKODE -- Développeur Web Fullstack -- Contact</title>
        <meta name='description' content='Contact' />
      </Helmet>
      <form className='Contact' onSubmit={sendEmail}>
        <Dialog
          open={isModalConfirm && isBlocking}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
              Voulez-vous vraiment quitter cette page ? Si oui, vos données
              saisies pourraient ne pas être enregistrées.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <button
              className='Contact__buttons__item Contact__buttons__item--cancel'
              type='button'
              onClick={handleCloseModal}>
              Annuler
            </button>
            <button
              type='button'
              className='Contact__buttons__item'
              onClick={handleLeavePage}>
              OK
            </button>
          </DialogActions>
        </Dialog>
        {isLoading && <CircularProgress />}
        {flashMessage && errorsList.length > 0 && (
          <Fade>
            <div className='Contact__flash Contact__flash--error'>
              <ul>
                {errorsList.map((error) => (
                  <li key={error}>{error}</li>
                ))}
              </ul>
            </div>
          </Fade>
        )}
        {flashMessage && successMessage && (
          <Fade>
            <div className='Contact__flash Contact__flash--success'>
              {successMessage}
            </div>
          </Fade>
        )}
        <label htmlFor='email'>Email</label>
        <input
          className='Contact__field'
          type='text'
          value={email}
          onChange={(e) => handleChangeInput(e, "email")}
          name='email'
          placeholder='Votre email'
        />
        <label htmlFor='subject'>Sujet</label>
        <input
          className='Contact__field'
          type='text'
          value={subject}
          onChange={(e) => handleChangeInput(e, "subject")}
          name='subject'
          placeholder='Sujet'
        />
        <label htmlFor='message'>Message</label>
        <textarea
          className='Contact__field'
          placeholder='Message'
          value={message}
          onChange={(e) => handleChangeInput(e, "message")}
          name='message'
          rows='6'
        />
        <div className='Contact__buttons'>
          <button className='Contact__buttons__item' type='submit'>
            Envoyer
          </button>
          <button
            className='Contact__buttons__item Contact__buttons__item--cancel'
            type='reset'
            onClick={resetForm}>
            Reset
          </button>
        </div>
      </form>
    </Fade>
  );
};

const mapStateToProps = (state) => ({
  email: state.contactForm.email,
  subject: state.contactForm.subject,
  message: state.contactForm.message,
  errorsList: state.contactForm.errorsList,
  flashMessage: state.contactForm.flashMessage,
  successMessage: state.contactForm.successMessage,
  isLoading: state.contactForm.isLoading,
  isBlocking: state.contactForm.isBlocking,
  isModalConfirm: state.contactForm.isModalConfirm,
  pageToGo: state.contactForm.pageToGo,
  leavePage: state.contactForm.leavePage,
  contact: state.contact,
});

const mapDispatchToProps = (dispatch) => ({
  handleChangeInput: (e, field) => {
    dispatch(changeInputValue(e.target.value, field));
  },

  displayErrors: (errors) => {
    dispatch(displayErrors(errors));
  },

  closeFlashMessage: () => {
    dispatch(closeFlashMessage());
  },

  displaySuccessMessage: () => {
    dispatch(displaySuccessMessage());
  },

  resetForm: () => {
    scrollToTop();
    dispatch(resetForm());
  },

  setIsLoadingToogle: () => {
    dispatch(setIsLoadingToogle());
  },

  setIsBlocking: (isBlocking) => {
    dispatch(setIsBlocking(isBlocking));
  },

  handleCloseModal: () => {
    dispatch(closeModalConfirm());
  },

  handleLeavePage: () => {
    dispatch(leavePage());
  },

  setHoverOnCareer: () => {
    dispatch(setHoverOnCareer());
  },

  setHoverOnSkills: () => {
    dispatch(setHoverOnSkills());
  },

  setHoverOnProjects: () => {
    dispatch(setHoverOnProjects());
  },

  setHoverOnContact: () => {
    dispatch(setHoverOnContact());
  },

  setPageToGo: () => {
    dispatch(setPageToGo(""));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Contact);
