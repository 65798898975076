import React from "react";
import PropTypes from "prop-types";
import Fade from "react-reveal/Fade";

import "../styles/degree.scss";

const Degree = ({ degree }) => {
  const { title, year, labelUrl, organization, badgePath  } = degree;

  return (
    <Fade>
      <div className='Degree'>
        <div className='Degree__content'>
          <h3 className='Degree__content__title'>{title}</h3>
          <p className='Degree__content__year'>{year}</p>
        </div>
        {badgePath && (
          <a href={labelUrl} target='_blank' rel='noopener noreferrer'>
            <img
              className='Degree__labelLogo'
              src={badgePath}
              alt={organization}
            />
          </a>
        )}
      </div>
    </Fade>
  );
};

Degree.propTypes = {
  degree: PropTypes.shape({
    title: PropTypes.string.isRequired,
    year: PropTypes.number.isRequired,
  }),
};
export default Degree;
