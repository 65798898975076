import React from "react";
import PropTypes from "prop-types";
import Fade from "react-reveal/Fade";

import "../styles/job.scss";

const Job = ({ job }) => {
  const { title, company, place, startDate, endDate, missions } = job;
  const month = [
    "Janv.",
    "Fév.",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Aout",
    "Sept.",
    "Oct.",
    "Nov.",
    "Dec.",
  ];

  const start = new Date(startDate);
  const end = new Date(endDate);
  return (
    <Fade>
      <div className='Job'>
        <h3 className='Job__title'>{title}</h3>
        <p className='Job__company'>
          {company}, {place}
        </p>
        <p className='Job__dates'>
          {month[start.getMonth()]} {start.getFullYear()} -{" "}
          {month[end.getMonth()]} {end.getFullYear()}
        </p>
        <ul className='Job__missions'>
          {missions.map((mission) => (
            <li key={mission}>{mission}</li>
          ))}
        </ul>
      </div>
    </Fade>
  );
};

Job.propTypes = {
  job: PropTypes.shape({
    title: PropTypes.string.isRequired,
    company: PropTypes.string.isRequired,
    place: PropTypes.string.isRequired,
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    missions: PropTypes.array.isRequired,
  }),
};
export default Job;
