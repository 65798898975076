/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";

import { connect } from "react-redux";

import "../styles/main-nav.scss";

import {
  setHoverOnCareer,
  setHoverOnSkills,
  setHoverOnProjects,
  setHoverOnContact,
  resetForm,
  setModalConfirm,
  setPageToGo
} from "../store/actions";

const MainNav = ({
  handleClickOnCareer,
  handleClickOnSkills,
  handleClickOnProjects,
  handleClickOnContact,
  isHome,
  isCareer,
  isSkills,
  isProjects,
  isContact,
  isBlocking,
  isModalConfirm,
}) => {
  const location = useLocation();
  const career = useRef(null);
  const skills = useRef(null);
  const projects = useRef(null);
  const contact = useRef(null);

  let pathLength = 0;

  useEffect(() => {
    if (location.pathname === "/parcours" && !isModalConfirm) {
      handleClickOnCareer();
    }
  }, [location.pathname, isModalConfirm]);

  useEffect(() => {
    if (location.pathname === "/skills" && !isModalConfirm) {
      handleClickOnSkills();
    }
  }, [location.pathname, isModalConfirm]);

  useEffect(() => {
    if (location.pathname === "/projets" && !isModalConfirm) {
      handleClickOnProjects();
    }
  }, [location.pathname, isModalConfirm]);

  useEffect(() => {
    if (location.pathname === "/contact" && !isModalConfirm) {
      handleClickOnContact();
    }
  }, [location.pathname, isModalConfirm]);

  useEffect(() => {
    if (location.pathname === "/") {
      career.current.style.strokeDashoffset = 333.5;
      skills.current.style.strokeDashoffset = 272.6;
      projects.current.style.strokeDashoffset = 317.3;
      contact.current.style.strokeDashoffset = 344;
    }
  }, [location.pathname]);

  useEffect(() => {
    if (isHome) {
      career.current.style.strokeDashoffset = 333.5;
      skills.current.style.strokeDashoffset = 272.6;
      projects.current.style.strokeDashoffset = 317.3;
      contact.current.style.strokeDashoffset = 344;
    }
  }, [isHome]);

  useEffect(() => {
    pathLength = career.current.getTotalLength();
    career.current.style.strokeDasharray = pathLength;
    career.current.style.strokeDashoffset = pathLength;
    career.current.style.stroke = "#676CEF";
  }, []);

  useEffect(() => {
    pathLength = skills.current.getTotalLength();
    skills.current.style.strokeDasharray = pathLength;
    skills.current.style.strokeDashoffset = pathLength;
    skills.current.style.stroke = "#676CEF";
  }, []);

  useEffect(() => {
    pathLength = projects.current.getTotalLength();
    projects.current.style.strokeDasharray = pathLength;
    projects.current.style.strokeDashoffset = pathLength;
    projects.current.style.stroke = "#676CEF";
  }, []);

  useEffect(() => {
    pathLength = contact.current.getTotalLength();
    contact.current.style.strokeDasharray = pathLength;
    contact.current.style.strokeDashoffset = pathLength;
    contact.current.style.stroke = "#676CEF";
  }, []);

  if (isCareer) {
    career.current.style.transitionDuration = "0.5s";
    career.current.style.strokeDashoffset = 0;
    projects.current.style.strokeDashoffset = 317.3;
    skills.current.style.strokeDashoffset = 272.6;
    contact.current.style.strokeDashoffset = 344;
  }

  if (isSkills) {
    skills.current.style.transitionDuration = "0.5s";
    skills.current.style.strokeDashoffset = 0;
    career.current.style.strokeDashoffset = 333.5;
    projects.current.style.strokeDashoffset = 317.3;
    contact.current.style.strokeDashoffset = 344;
  }

  if (isProjects) {
    projects.current.style.transitionDuration = "0.5s";
    projects.current.style.strokeDashoffset = 0;
    career.current.style.strokeDashoffset = 333.5;
    skills.current.style.strokeDashoffset = 272.6;
    contact.current.style.strokeDashoffset = 344;
  }

  if (isContact) {
    contact.current.style.transitionDuration = "0.5s";
    contact.current.style.strokeDashoffset = 0;
    career.current.style.strokeDashoffset = 333.5;
    projects.current.style.strokeDashoffset = 317.3;
    skills.current.style.strokeDashoffset = 272.6;
  }

  return (
    <div className='MainNav'>
      <NavLink
        to='/parcours'
        className='MainNav__item'
        onClick={(e) => handleClickOnCareer(e, isBlocking, '/parcours')}>
        Parcours
        <svg
          width='142'
          height='75'
          viewBox='0 0 142 75'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'>
          <path
            ref={career}
            d='M67.9045 5.19808C50.164 -1.7345 31.0881 9.1777 15.2792 17.8844C5.49627 23.2724 3 31.7946 3 42.086C3 59.6074 22.5159 66.0408 37.4013 69.3128C56.8946 73.5977 77.0423 72.1182 96.6535 69.3128C108.707 67.5886 126.096 66.1783 135.343 56.9192C141.033 51.2211 139.572 32.0293 134.466 26.2769C124.404 14.9421 111.068 12.2244 96.0688 12.2244C91.7367 12.2244 73.5397 11.478 71.4129 15.7375'
            stroke='#676CEF'
            strokeWidth='6'
            strokeLinecap='round'
          />
        </svg>
      </NavLink>
      <NavLink
        to='/skills'
        className='MainNav__item'
        onClick={(e) => handleClickOnSkills(e, isBlocking, '/skills')}>
        Skills
        <svg
          width='121'
          height='69'
          viewBox='0 0 121 69'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'>
          <path
            ref={skills}
            d='M68.9803 4.01475C48.1536 -0.0490122 3.32309 8.64014 3.32309 36.9476C3.32309 64.9665 40.8728 65.9202 62.4146 65.9202C84.0732 65.9202 117.754 56.5624 117.754 30.2777C117.754 21.5534 112.852 11.5184 102.747 11.5184C99.0593 11.5184 86.706 16.1127 85.8636 15.2703'
            stroke='#676CEF'
            strokeWidth='6'
            strokeLinecap='round'
          />
        </svg>
      </NavLink>
      <NavLink
        to='/projets'
        className='MainNav__item'
        onClick={(e) => handleClickOnProjects(e, isBlocking, '/projets')}>
        Projets
        <svg
          width='140'
          height='89'
          viewBox='0 0 140 89'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'>
          <path
            ref={projects}
            d='M44.7174 21.0737C62.9974 3.83832 109.462 -6.71493 125.486 17.3219C131.39 26.1768 139.722 36.0749 135.7 47.3366C130.344 62.3317 108.07 76.9932 92.6577 81.1032C66.4424 88.0939 27.8401 88.6128 7.19901 67.9718C-5.73002 55.0427 19.9409 36.0811 31.586 36.0811'
            stroke='#676CEF'
            strokeWidth='6'
            strokeLinecap='round'
          />
        </svg>
      </NavLink>
      <NavLink
        to='/contact'
        className='MainNav__item'
        onClick={(e) => handleClickOnContact(e, isBlocking, '/contact')}>
        Contact
        <svg
          width='145'
          height='83'
          viewBox='0 0 145 83'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'>
          <path
            ref={contact}
            d='M82.973 8.57617C57.8374 3.05859 26.8839 -2.44121 9.81203 23.1667C-6.47509 47.5973 10.5687 79.8612 39.7226 79.8612C60.6903 79.8612 80.5393 81.0518 100.794 75.1714C114.795 71.1067 139.008 66.0957 141.022 47.9705C142.344 36.0719 139.203 22.3959 128.725 15.1419C121.572 10.19 111.886 10.4521 103.608 10.4521C99.3865 10.4521 94.5278 10.4521 101.732 10.4521'
            stroke='#676CEF'
            strokeWidth='6'
            strokeLinecap='round'
          />
        </svg>
      </NavLink>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isHome: state.isHome,
  isCareer: state.career,
  isSkills: state.skills,
  isProjects: state.projects,
  isContact: state.contact,
  isBlocking: state.contactForm.isBlocking,
  isModalConfirm: state.contactForm.isModalConfirm,
});

const mapDispatchToProps = (dispatch) => ({
  handleClickOnCareer: (e, isBlocking, pageToGo) => {
    if (isBlocking) {
      e.preventDefault();
      dispatch(setModalConfirm());
      dispatch(setPageToGo(pageToGo));
    } else {
      dispatch(setHoverOnCareer());
      dispatch(resetForm());
    }
  },
  handleClickOnSkills: (e, isBlocking, pageToGo) => {
    if (isBlocking) {
      e.preventDefault();
      dispatch(setModalConfirm());
      dispatch(setPageToGo(pageToGo));
    } else {
      dispatch(setHoverOnSkills());
      dispatch(resetForm());
    }
  },
  handleClickOnProjects: (e, isBlocking, pageToGo) => {
    if (isBlocking) {
      e.preventDefault();
      dispatch(setModalConfirm());
      dispatch(setPageToGo(pageToGo));
    } else {
      dispatch(setHoverOnProjects());
      dispatch(resetForm());
    }
  },
  handleClickOnContact: (e, isBlocking, pageToGo) => {
    if (isBlocking) {
      e.preventDefault();
      dispatch(setModalConfirm());
      dispatch(setPageToGo(pageToGo));
    } else {
      dispatch(setHoverOnContact());
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MainNav);
