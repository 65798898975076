const skills = {
  frontEnd: {
    html: ["Sémantique", "Accessibilité"],
    css: ["Bulma", "Bootstrap", "SASS", "BEM", "Flexbox", "Responsive"],
    javascript: ["ES6", "yarn/npm", "Webpack", "React", "Redux", "Jest"],
  },
  backEnd: {
    php: [
      "Symfony",
      "Laravel",
      "Composer",
      "PHPUnit",
      "PSR",
      "Doctrine",
      "Eloquent",
    ],
    node: ["Express", "Sequelize", "nodemon", "PM2"],
    webServer: ["Apache", "Nginx"],
    datas: ["MySQL", "MariaDB", "PostgreSQL"],
    auth: ["JWT", "OAuth"],
  },
  others: [
    "Git - Github",
    "HTTP/HTTPS",
    "Unix",
    "Terminal/Shell",
    "SSH",
    "Wordpress",
    "Encodage",
    "Algo",
    "JSON",
    "XML",
    "Regex",
  ],
};

export default skills;
