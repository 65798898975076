const projects = [
  {
    title: "La Taupe",
    year: 2021,
    description: "Conception et réalisation de la Taupe, un site qui donne des informations clés sur les bars, cafés ou restaurants en temps réel et dans un rayon limité.",
    stack: ["react", "redux", "node", "express", "postgresql"],
    desktopPreviewPath: "/images/lataupe.png",
    mobilePreviewPath: "/images/lataupe_mobile.png",
    url: "https://lataupe.loukakode.com/"
  },
  {
    title: "O'Rando",
    year: 2021,
    description: "Conception et réalisation de O'Rando (et de son back-office administrateur), un site de mise en relation d'utilisateurs souhaitant faire de la randonnée en groupe.",
    stack: ["react", "redux", "axios", "symfony", "doctrine", "twig", "mysql"],
    desktopPreviewPath: "/images/orando.png",
    mobilePreviewPath: "/images/orando_mobile.png",
  },
  {
    title: "Le Père Lachaise Brasserie",
    year: 2020,
    description: "Réalisation du site du Père Lachaise Brasserie située à Paris (11e arrondissement), présentant le lieu et ses services.",
    stack: ["wordpress", "php"],
    desktopPreviewPath: "/images/lbrasserie.png",
    mobilePreviewPath: "/images/lpb-mobile.png",
    url: "https://leperelachaise-brasserie.com/",
  },
];

export default projects;
