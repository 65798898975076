import React from "react";
import PropTypes from "prop-types";
import Fade from "react-reveal/Fade";

import "../styles/study.scss";

const Study = ({ study }) => {
  const { title, school, startDate, endDate, schoolLogoPath, schoolUrl } =
    study;
  const month = [
    "Janv.",
    "Fév.",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Aout",
    "Sept.",
    "Oct.",
    "Nov.",
    "Dec.",
  ];

  const start = new Date(startDate);
  const end = new Date(endDate);
  return (
    <Fade>
      <div className='Study'>
        <div className='Study__content'>
          <h3 className='Study__content__title'>{title}</h3>
          <p className='Study__content__school'>{school}</p>
          <p className='Study__content__dates'>
            {month[start.getMonth()]} {start.getFullYear()} -{" "}
            {month[end.getMonth()]} {end.getFullYear()}
          </p>
        </div>
        {schoolLogoPath && (
          <a href={schoolUrl} target='_blank' rel='noopener noreferrer'>
            <img
              className='Study__schoolLogo'
              src={schoolLogoPath}
              alt={school}
            />
          </a>
        )}
      </div>
    </Fade>
  );
};

Study.propTypes = {
  Study: PropTypes.shape({
    title: PropTypes.string.isRequired,
    school: PropTypes.string.isRequired,
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
  }),
};
export default Study;
