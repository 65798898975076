import React from "react";
import { Helmet } from "react-helmet";

import career from "../datas/career";

import Job from "../components/Job";
import Study from "../components/Study";
import Degree from "../components/Degree";

import "../styles/career.scss";

const Career = () => {
  return (
    <div className='Career'>
      <Helmet>
        <title>LOUKAKODE -- Développeur Web Fullstack -- Parcours</title>
        <meta name='description' content="Parcours" />
        <meta name='keywords' content="développeur, php ,e-commerce, wordpress, fullstack, web, opquast" />
      </Helmet>
      <section className='Career__jobs'>
        <h2 className='Career__jobs__title'>Expériences</h2>
        {career.jobs.map((job) => (
          <Job key={job.title} job={job} />
        ))}
      </section>
      <section className='Career__studies'>
        <h2 className='Career__studies__title'>Formations</h2>
        {career.studies.map((study) => (
          <Study key={study.title} study={study} />
        ))}
      </section>
      <section className='Career__degrees'>
        <h2 className='Career__degrees__title'>Diplômes / Certifications</h2>
        {career.degrees.map((degree) => (
          <Degree key={degree.title} degree={degree} />
        ))}
      </section>
    </div>
  );
};

export default Career;
