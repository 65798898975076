import skills from "../datas/skills";
import Fade from "react-reveal/Fade";
import { Helmet } from "react-helmet";

import "../styles/skills.scss";

const Skills = () => {
  return (
    <div className='Skills'>
      <Helmet>
        <title>LOUKAKODE -- Développeur Web Fullstack -- Skills</title>
        <meta name='description' content="Compétences" />
        <meta name='keywords' content="frontend, backend ,html, css, javascript, react, php, node, apache, mysql, symfony, doctrine" />
      </Helmet>
      <Fade>
        <section className='Skills__domain'>
        <h2 className='Skills__domain__title'>Front-end</h2>
        <div className='Skills__domain__container'>
          <div className='Skills__domain__speciality'>
            <h3>HTML</h3>
            <ul className='Skills__domain__speciality__list'>
              {skills.frontEnd.html.map((item) => (
                <li key={item}>{item}</li>
              ))}
            </ul>
          </div>
          <div className='Skills__domain__speciality'>
            <h3>CSS</h3>
            <ul className='Skills__domain__speciality__list'>
              {skills.frontEnd.css.map((item) => (
                <li key={item}>{item}</li>
              ))}
            </ul>
          </div>
          <div className='Skills__domain__speciality'>
            <h3>Javascript</h3>
            <ul className='Skills__domain__speciality__list'>
              {skills.frontEnd.javascript.map((item) => (
                <li key={item}>{item}</li>
              ))}
            </ul>
          </div>
        </div>
      </section>
      </Fade>
      <Fade>
        <section className='Skills__domain'>
        <h2 className='Skills__domain__title'>Back-end</h2>
        <div className='Skills__domain__container'>
          <div className='Skills__domain__speciality'>
            <h3>PHP</h3>
            <ul className='Skills__domain__speciality__list'>
              {skills.backEnd.php.map((item) => (
                <li key={item}>{item}</li>
              ))}
            </ul>
          </div>
          <div className='Skills__domain__speciality'>
            <h3>Node.js</h3>
            <ul className='Skills__domain__speciality__list'>
              {skills.backEnd.node.map((item) => (
                <li key={item}>{item}</li>
              ))}
            </ul>
          </div>
          <div className='Skills__domain__speciality'>
            <h3>Serveur web</h3>
            <ul className='Skills__domain__speciality__list'>
              {skills.backEnd.webServer.map((item) => (
                <li key={item}>{item}</li>
              ))}
            </ul>
          </div>
          <div className='Skills__domain__speciality'>
            <h3>Données</h3>
            <ul className='Skills__domain__speciality__list'>
              {skills.backEnd.datas.map((item) => (
                <li key={item}>{item}</li>
              ))}
            </ul>
          </div>
          <div className='Skills__domain__speciality'>
            <h3>API Rest</h3>
          </div>
          <div className='Skills__domain__speciality'>
            <h3>Authentification</h3>
            <ul className='Skills__domain__speciality__list'>
              {skills.backEnd.auth.map((item) => (
                <li key={item}>{item}</li>
              ))}
            </ul>
          </div>
        </div>
      </section>
      </Fade>
      <Fade>
        <section className='Skills__domain'>
        <h2 className='Skills__domain__title'>Autres</h2>
        <ul className='Skills__others'>
              {skills.others.map((item) => (
                <li key={item}>{item}</li>
              ))}
            </ul>
      </section>
      </Fade>
      
    </div>
  );
};

export default Skills;
