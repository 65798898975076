import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Helmet } from "react-helmet";

import "./styles/app.scss";

import Header from "./components/Header";
import MainNav from "./components/MainNav";
import Footer from "./components/Footer";

import Home from "./pages/Home";
import Career from "./pages/Career";
import Skills from "./pages/Skills";
import Projects from "./pages/Projects";
import Contact from "./pages/Contact";
import NotFound from "./pages/NotFound";

function App() {
  return (
    <Router>
      <div className='App'>
        <Helmet>
          <meta charSet='utf-8' />
          <title>LOUKAKODE -- Développeur Web Fullstack -- Paris</title>
          <link rel='canonical' href='https://loukakode.com' />
        </Helmet>
        <div className='App__content'>
          <Header />
          <MainNav />
          <Switch>
            <Route exact path='/' component={Home} />
            <Route exact path='/parcours' component={Career} />
            <Route exact path='/skills' component={Skills} />
            <Route exact path='/projets' component={Projects} />
            <Route exact path='/contact' component={Contact} />
            <Route component={NotFound} />
          </Switch>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
